import analytics from '@capturi/analytics'

export enum Event {
  LoadMore = 'commentsPage_loadMore',
  GoToConversation = 'commentsPage_goToConversation',
}

export const log = (event: Event): void => {
  analytics.event(event)
}
